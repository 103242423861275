html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: blackscale;
}

html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

/* https://stackoverflow.com/questions/9218574/get-rid-of-magnifying-glass-in-ios-web-app */
:not(input) {
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

// 単品共有時のハイライトカラーを無色にする
::target-text {
  background-color: transparent;
}

button:focus {
  outline: 0 !important;
}

.swiper-pagination-bullet {
  background-color: rgba(51, 51, 51, 0.2) !important;
  &-active {
    background-color: #666666 !important;
  }
}
